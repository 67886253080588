<template>
    <div>
        <div class="flex filter mx-0.5">
            <div><input type="text" placeholder="Юр.лицо" v-model="filter.uf_name"></div>
            <div><input type="text" placeholder="УНП" v-model="filter.uf_unp"></div>
            <div><input type="text" placeholder="Договор" v-model="filter.uf_contract"></div>
            <div>
                <button class="h-full w-full bg-blue-400" @click="unset">Сбросить</button>
            </div>
        </div>
        <div class="h-128 overflow-y-scroll my-0.5">
            <table>
                <tbody>
                <tr class="cursor-pointer"
                    :class="{'dark:bg-gray-800 bg-white  dark:hover:bg-gray-700 hover:bg-gray-300': org.id !== organization.id,
                        'bg-green-500 text-white': org.id === organization.id
                        }"
                    v-for="org in filteredOrgs" :key="'org-'+org.id"
                    @dblclick="selectOrganization(org)"
                >
                    <td class="p-3 ">{{ org.uf_name }}</td>
                    <td class="p-3 ">{{ org.uf_unp }}</td>
                    <td class="p-3 ">{{ org.uf_contract }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SimpleContractsTable",
    data: () => ({
        organization: {},
        filter: {
            uf_name: '',
            uf_unp: '',
            uf_contract: ''
        },
    }),
    computed: {
        ...mapGetters({
            organizations: 'admin/contractsSimple'
        }),

        filteredOrgs() {
            let items = this.organizations
            let filter = {}
            for (let key in this.filter) {
                if (this.filter[key].length) {
                    filter[key] = this.filter[key]
                }
            }
            if (Object.keys(filter).length) {
                for (let key in filter) {
                    items = items.filter(i => {
                        if (i[key] && i[key] !== null) {
                            return i[key].toLowerCase().includes(filter[key].toLowerCase())
                        }

                    })
                }
                return items
            } else {
                return this.organizations
            }

        },
        isOrgSelected() {
            return Object.hasOwnProperty.call(this.organization, 'id') && this.organization.id > 0;
        },
    },
    methods: {
        selectOrganization(org) {
            this.organization = org
            this.$emit('select', this.organization)
        },
        unset() {
            this.filter = {
                uf_name: '',
                uf_unp: '',
                uf_contract: ''
            }
        },
    }

}
</script>

<style scoped>
table {
    @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm;
}

thead {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

tbody {
    @apply dark:text-gray-400 text-gray-600
}

.filter div {
    @apply w-1/4 mr-1;
}

input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}

.confirm input {
    @apply w-1/2;
}
</style>